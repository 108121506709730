import React, {useContext, useEffect, useState} from 'react'
import {GetAccount} from "../helpers/functions";
import {Link, useNavigate} from "react-router-dom";
import {baseApi} from "../helpers/config";
import Accordion from 'react-bootstrap/Accordion';
import {Alert, Badge, Button, Card, Col, Container, Form, ListGroup, Row} from "react-bootstrap";


export default function HomePage() {

    const [document, setDocument] = useState([]);

    const [curentDate, setCurentDate] = useState("");
    const [defaultValue, setDefaultValue] = useState("");
    const [check, setCheck] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {

            const dValue = new Date().toISOString().split('T')[0] // yyyy-mm-dd

            setDefaultValue(dValue)
            setCurentDate(dValue.replaceAll("/", "-"))
        }, [])


    useEffect(() => {

        const userId = btoa(GetAccount('userId'))

        fetch(`${baseApi()}Documents/completed/${userId}/${curentDate}`)
            .then(r => r.json().then(data => ({status: r.status, result: data})))
            .then(data => {
                if (data.status !== 200) {
                    alert('Usuario no encontrado')
                    navigate(`/`);
                    return;
                }

                setDocument(data.result);
                setCheck(true)
            })
            .catch(error => console.error(error));


    }, [curentDate]);


    const Download = ({doc}) => {


        const onDownload = (id) => {
            navigate(`/app/view`, {state: {id: id}});
        }

        return <Button variant="link" onClick={() => onDownload(doc.submissionId)}>
            <i className="fa fa-file-pdf-o fa-3x"></i>
        </Button>

    }

    const Preview = (url, submitter, email, id, preview  = false) => {
        navigate(`/app/sign`, {state: {url: url, submitter: submitter, email: email, id: id , preview: preview}});
    }


    function handleChange(e) {
        setDefaultValue(e.target.value);
        setCheck(false)
        setCurentDate(e.target.value.replaceAll("/", "-"))
    }

    return <>
        <h4 style={{textAlign: 'center'}}>Contracts completed</h4>
        <Container>
        <Form>
            <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                <Form.Label column sm="2">
                    Date
                </Form.Label>
                <Col sm="2">
                    <Form.Control as="input" type="date" defaultValue={defaultValue}  onChange={handleChange} />
                </Col>
            </Form.Group>
        </Form>
        </Container>
      <Accordion key={1} defaultActiveKey={['0']} flush>
            {document?.map((c, inx) => (
                <Accordion.Item eventKey={inx} key={inx}>
                    <Accordion.Header>{c.contract}</Accordion.Header>
                    <Accordion.Body>
                        <ListGroup variant="flush">
                            {c?.documents?.map((doc, inx) => (
                                <ListGroup.Item key={inx}>
                                    <Card style={{backgroundColor: "#efeae6"}}>
                                        <Card.Body>
                                            <Row>
                                                <Col xs={3}><strong>{doc.name}</strong><br/><br/>
                                                    {doc.creationDateTime}<br/>{doc.creationHumanize}
                                                </Col>
                                                <Col xs={7}>
                                                    {doc?.submitters?.map((su, inx) => (
                                                        <Row key={inx}>
                                                            <Col xs={6}>
                                                                <h4>
                                                                    <small>{su.name} </small>
                                                                </h4>
                                                            </Col>
                                                            <Col xs={6}>
                                                                <h6>
                                                                    <small><i>[{su.signedDatetime}]</i></small> {" "}
                                                                    <Button variant="outline-info" onClick={() => Preview(su.embedSrc, su.name, su.email, su.id, true)}>
                                                                        <i className="fa fa-eye"></i> View
                                                                    </Button>
                                                                </h6>
                                                            </Col>

                                                        </Row>
                                                    ))}
                                                </Col>
                                                <Col xs={2} className="d-flex justify-content-center">
                                                    <Download doc={doc}/>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Accordion.Body>
                </Accordion.Item>
            ))}
        </Accordion>


        {check && document.length == 0 ? <>
            <Alert variant="light">
               <strong>{GetAccount('firstName')}</strong>, There are no documents signed on  <strong>{defaultValue}</strong>
            </Alert>
        </> :<></>}
    </>
}


