
import './App.css';

import React from "react"

import LayoutReport from './layouts/report'

import LoginPage from "./pages/loginPage";
import HomePage from "./pages/homePage";
import SignaturePage from "./pages/signaturePage";
import SignatureViewPage from "./pages/signatureViewPage";
import PendingPage from "./pages/documentByUserPage";
import CompletedPage from "./pages/SignedPage";
import CancelPage from "./pages/CancelPage";

import UsersPage from "./pages/usersPage";

import PageNotFound from "./components/share/error/404";
import {BrowserRouter, Route, Routes} from "react-router-dom";

function NotFoundPage() {
    return  <PageNotFound url=""/>
}

function App() {
  return <BrowserRouter>
      <Routes>
          <Route path="app" element={<LayoutReport/>}>
              <Route path="" element={<HomePage/>}/>
              <Route path="users" element={<UsersPage/>}/>
              <Route path="pendings" element={<PendingPage/>}/>
              <Route path="completed" element={<CompletedPage/>}/>
              <Route path="cancel" element={<CancelPage/>}/>
              <Route path="sign" element={<SignaturePage/>}/>
              <Route path="view" element={<SignatureViewPage/>}/>
          </Route>
          <Route path="/" element={<LoginPage/>}/>
          <Route path='*' element={<NotFoundPage/>}/>
      </Routes>
  </BrowserRouter>
}

export default App;

