import {useContext} from "react";


export default function Footer() {



    return <footer className="footer">
        <div className="container">

            <br/>
        </div>
    </footer>
}
