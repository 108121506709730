import {Link} from "react-router-dom";
import React from "react";
import logo from "../../../logo-clt.png";

export default function PageNotFound({
                                         url,
                                         title="Página no encontrada",
                                         message = "Lamentamos comunicarte que la página que buscas no se encuentra en nuestro servidor.",
                                     }) {
    return <>

        <header style={{backgroundColor:'#fff', border: "none" }}
                className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4 border-bottom">
            <div className="px-3">

                <img src={logo} className="App-logo" alt="logo"/>

            </div>

            <div className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
            </div>

            <div className="col-md-3 text-end">
                <Link className="btn btn-outline-primary me-2 text-primary" to={url}>Ir al
                    inicio</Link>
            </div>
        </header>
        <div className="px-4 py-5 text-center">
            <h1 className="display-5 fw-bold text-body-emphasis">{title}</h1>
            <div className="col-lg-6 mx-auto">{message}</div>
        </div>
    </>
}
