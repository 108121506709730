import React, {useContext} from "react";
import logo from '../../../logo-clt.png';
import {Dropdown} from "react-bootstrap";
import {GetAccount} from "../../../helpers/functions";
import {useNavigate} from "react-router-dom";

export default function Header() {
    const navigate = useNavigate();
    const account = GetAccount()

    const SignOut = () => {
        window.localStorage.clear();
        navigate(`/`);
    }

    return <>

        <header className="py-3 mb-3 border-bottom" style={{backgroundColor:'#fff', border: "none" }}>
            <div className="container-fluid d-grid gap-3 align-items-center body-header">
                <Dropdown>
                    <Dropdown.Toggle variant="secondary"
                                     style={{backgroundColor:'#fff', border: "none" }}
                                     className=" link-body-emphasis text-decoration-none">
                        <img src={logo} className="App-logo" alt="logo"/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item href="/app">
                            My Pending List
                        </Dropdown.Item>
                        <Dropdown.Item href="/app/completed">Signed List</Dropdown.Item>
                        <Dropdown.Item href="/app/cancel">To Cancel</Dropdown.Item>
                        <Dropdown.Item href="/app/pendings">Pending list per user</Dropdown.Item>

                        <Dropdown.Divider/>
                        <Dropdown.Item href="/app/users">Users List</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <div className="d-flex align-items-center">
                    <div className="w-100 me-3"></div>
                    <Dropdown>
                        <Dropdown.Toggle style={{backgroundColor:'#fff', border: "none" }} className="link-body-emphasis text-decoration-none">
                            <img src="/user-icon.png" alt="mdo" width="32" height="32"
                                 className="rounded-circle"/>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item active>
                                {`${account?.firstName} ${account?.lastName}` }
                            </Dropdown.Item>
                            <Dropdown.Item>My account</Dropdown.Item>
                            <Dropdown.Divider/>
                            <Dropdown.Item onClick={SignOut} >Sign out</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        </header>


    </>
}
