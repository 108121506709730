import React, {useContext, useEffect, useState} from 'react'
import {GetAccount} from "../helpers/functions";
import {Link, useNavigate} from "react-router-dom";
import {baseApi} from "../helpers/config";
import Accordion from 'react-bootstrap/Accordion';
import {Alert, Badge, Button, Card, Col, Container, ListGroup, Row} from "react-bootstrap";
import {getColor} from "../helpers/Models";


export default function HomePage() {

    const [document, setDocument] = useState([]);
    const [check, setCheck] = useState(false);

    const navigate = useNavigate();

    const onSubmit = (url, submitter, email, id, preview  = false) => {
        navigate(`/app/sign`, {state: {url: url, submitter: submitter, email: email, id: id , preview: preview}});
    }

    useEffect(() => {

        const userId = btoa(GetAccount('userId'))

        fetch(`${baseApi()}Documents/pendings/${userId}`)
            .then(r => r.json().then(data => ({status: r.status, result: data})))
            .then(data => {
                if (data.status !== 200) {
                    alert('Usuario no encontrado')
                    navigate(`/`);
                    return;
                }

                setDocument(data.result);
                setCheck(true)
            })
            .catch(error => console.error(error));


    }, []);


    const Buttonx = ({url, submitter, email, id, signed}) => {

        if (signed) return <>
            <Button variant="outline-secondary" disabled="disabled">
                <i className="fa fa-check"></i> Signed
            </Button>
            {" "}
            <Button variant="outline-info" onClick={() => onSubmit(url, submitter, email, id, true)}>
                <i className="fa fa-eye"></i> View
            </Button>
        </>

        return <Button variant="outline-primary" onClick={() => onSubmit(url, submitter, email, id)}>
            <i className="fa fa-pencil-square"></i> Sign Here
        </Button>
    }

    return <>
        <h4 style={{textAlign:'center'}}>Contracts with pending signatures</h4>
      <Accordion key={1} defaultActiveKey={['0']} flush>
            {document?.map((c, inx) => (
                <Accordion.Item eventKey={inx} key={inx}>
                    <Accordion.Header>{c.contract}</Accordion.Header>
                    <Accordion.Body>
                        <ListGroup variant="flush">
                            {c?.documents?.map((doc, inx) => (
                                <ListGroup.Item key={inx}>
                                    <Card style={{backgroundColor: "#efeae6"}}>
                                        <Card.Body>
                                            <Row>
                                                <Col xs={4}><strong>{doc.name}</strong><br/><br/>
                                                    {doc.creationDateTime}<br/>{doc.creationHumanize}
                                                </Col>
                                                <Col xs={8}>
                                                    {doc?.submitters?.map((su, inx) => (
                                                        <Row key={inx}>
                                                            <Col xs={8}>
                                                                <h4>
                                                                    <Badge pill bg={getColor(su.status)}>{su.status}</Badge>
                                                                    {" "}
                                                                    <small>{su.name}</small>
                                                                </h4>
                                                            </Col>
                                                            <Col xs={4}>
                                                                <div>
                                                                    <Buttonx url={su.embedSrc} submitter={su.name}
                                                                             id={su.id}
                                                                             email={su.email} signed={su.signed}/>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Accordion.Body>
                </Accordion.Item>
            ))}
        </Accordion>

        { check && document.length == 0 ? <>
            <Alert variant="light">
               <strong>{GetAccount('firstName')}</strong> don't have contracts pending this moment.
            </Alert>
        </> :<></>}

    </>
}


