import {DocusealForm} from "@docuseal/react";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {baseApi} from "../helpers/config";
import {Document} from "react-pdf";


export default function SignatureViewPage() {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {state} = useLocation();

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);


    const [url, setUrl] = useState(null);

    const base64 ='data:application/pdf;base64,';
    const pdfContentType = 'application/pdf';



    useEffect(() => {
        onSubmit(state.id)
    })

    const onSubmit = (id) => {



        fetch(`${baseApi()}Documents/submissions/${id}`)
            .then(r => r.json().then(data => ({status: r.status, result: data})))
            .then(data => {
                if(data.status !== 200) {
                    return;
                }


                setUrl(data.result.url)


            })
            .catch(error => console.error(error));
    }
    return url === null ? <div>Cargando....</div> :  <embed style={{width:'100%',height: 800}} src={`data:application/pdf;base64,${url}`} />
}


