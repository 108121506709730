import {Outlet} from "react-router-dom";
import Footer from "../components/share/footer";
import Header from "../components/share/header"

export default function Report() {
    return <>
            <Header/>
            <main role="main" >
                <Outlet/>
            </main>
            <Footer/>
    </>

}
