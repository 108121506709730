
export const getColor = (type) => {

    const status = {
        'awaiting': 'secondary',
        'open': 'warning',
        'completed': 'success',
        'default': 'dark'
    };

    return status[type] || status['default'];
}
