import React, {useContext, useEffect, useState} from 'react'
import {Table, Container} from "react-bootstrap";
import {GetAccount} from "../helpers/functions";
import {Link, useNavigate} from "react-router-dom";
import {baseApi} from "../helpers/config";


export default function UsersPage() {

    const [users, setUsers] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {

        const userId = btoa(GetAccount('userId'))

        fetch(`${baseApi()}User/All/${userId}`)
            .then(r => r.json().then(data => ({status: r.status, result: data})))
            .then(data => {
                if (data.status !== 200) {
                    alert('Usuario no encontrado')
                    navigate(`/`);
                    return;
                }

                setUsers(data.result);
            })
            .catch(error => console.error(error));


    }, []);


    return <>
        <h4 className="text-center">Users List</h4>
        <br/>
        <Container>
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>User</th>
                    <th>Name</th>
                    <th>Email</th>
                </tr>
                </thead>
                <tbody>
                {users?.map((c, inx) => (
                    <tr key={inx}>
                        <td>{c.userName}</td>
                        <td>{`${c.firstName} ${c.lastName}`}</td>
                        <td>{c.email}</td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </Container>
    </>
}
