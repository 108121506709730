import React, {useContext, useEffect, useState} from 'react'
import {Table, Container} from "react-bootstrap";
import {GetAccount} from "../helpers/functions";
import {Link, useNavigate} from "react-router-dom";
import {baseApi} from "../helpers/config";


export default function DocumentByUserPage() {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [users, setUsers] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const navigate = useNavigate();

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        const userId = btoa(GetAccount('userId'))

        fetch(`${baseApi()}Documents/groupAll/${userId}`)
            .then(r => r.json().then(data => ({status: r.status, result: data})))
            .then(data => {
                if (data.status !== 200) {
                    alert('Usuario no encontrado')
                    navigate(`/`);
                    return;
                }

                setUsers(data.result);
            })
            .catch(error => console.error(error));


    }, []);


    return <>
        <h4 className="text-center">Pending documents per user</h4>
        <hr/>
        <Container>
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>User</th>
                    <th>Documents</th>
                    <th>Start</th>
                </tr>
                </thead>
                <tbody>
                {users?.map((c, inx) => (
                    <tr key={inx}>
                        <td>{c.firstName}</td>
                        <td style={{textAlign:"right", width:50}}>{c.total}</td>
                        <td style={{width:200}}>{c.firstDateString}</td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </Container>
    </>
}
