import React, {useContext, useEffect, useState} from 'react'
import {GetAccount} from "../helpers/functions";
import {Link, useNavigate} from "react-router-dom";
import {baseApi} from "../helpers/config";
import Accordion from 'react-bootstrap/Accordion';
import {Alert, Badge, Button, Card, Col, Container, Form, ListGroup, Row} from "react-bootstrap";
import Swal from 'sweetalert2'


export default function HomePage() {

    const [document, setDocument] = useState([]);
    const [check, setCheck] = useState(false);

    const navigate = useNavigate();

    const loadData = () => {
        const userId = btoa(GetAccount('userId'))

        fetch(`${baseApi()}Documents/pendings/${userId}`)
            .then(r => r.json().then(data => ({status: r.status, result: data})))
            .then(data => {
                if (data.status !== 200) {
                    alert('Usuario no encontrado')
                    navigate(`/`);
                    return;
                }

                setDocument(data.result);
                setCheck(true)
            })
            .catch(error => console.error(error));
    }


    useEffect(() => {
        loadData();
    }, []);


    const Cancel = ({doc}) => {

        const onCancel = () => {
            Swal.fire({
                title: `Remove Contract`,
                text: `${doc.name}`,
                showDenyButton:true,
                confirmButtonText: 'Remove Contract',
                confirmButtonColor: '#dc3545',
                denyButtonText: 'Cancel',
                denyButtonColor: '#7a7979',
                customClass: {
                    actions: 'my-actions',
                    confirmButton: 'order-2',
                    denyButton: 'order-3',
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    const options = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            id: doc.submissionId
                        })
                    };

                    const userId = btoa(GetAccount('userId'))

                    fetch(`${baseApi()}Documents/cancel/${userId}`, options)
                        .then(r => r.json().then(data => ({status: r.status, result: data})))
                        .then(data => {
                            if(data.status !== 200) {
                                return;
                            }
                            loadData();
                        })
                        .catch(error => {
                            console.error(error)
                        });


                }
            })

        }

        return <Button variant="link" onClick={() => onCancel()}>
            <i className="fa fa-ban fa-3x" style={{color:'#dc3545'}}></i>
        </Button>

    }

    return <>
        <h4 style={{textAlign:'center'}}>Contracts with pending signatures</h4>
        <hr/>
        <Accordion key={1} defaultActiveKey={['0']} flush>
            {document?.map((c, inx) => (
                <Accordion.Item eventKey={inx} key={inx}>
                    <Accordion.Header>{c.contract}</Accordion.Header>
                    <Accordion.Body>
                        <ListGroup variant="flush">
                            {c?.documents?.map((doc, inx) => (
                                <ListGroup.Item key={inx}>
                                    <Card style={{backgroundColor: "#efeae6"}}>
                                        <Card.Body>
                                            <Row>
                                                <Col xs={3}><strong>{doc.name}</strong><br/><br/>
                                                    {doc.creationDateTime}<br/>{doc.creationHumanize}
                                                </Col>
                                                <Col xs={7}>
                                                    {doc?.submitters?.map((su, inx) => (
                                                        <Row key={inx}>
                                                            <Col xs={6}>
                                                                <h4>
                                                                    <small>{su.name} </small>
                                                                </h4>
                                                            </Col>
                                                            <Col xs={6}>
                                                                <h6>
                                                                    <small><i>[{su.signedDatetime}]</i></small>
                                                                </h6>
                                                            </Col>

                                                        </Row>
                                                    ))}
                                                </Col>
                                                <Col xs={2} className="d-flex justify-content-center">
                                                    <Cancel doc={doc}/>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Accordion.Body>
                </Accordion.Item>
            ))}
        </Accordion>


        {check && document.length == 0 ? <>
            <Alert variant="light">
                <strong>{GetAccount('firstName')}</strong> don't have contracts pending this moment.
            </Alert>
        </> : <></>}
    </>
}


