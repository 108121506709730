import React, {useState} from "react";

import {Form} from 'react-bootstrap'
import {Controller, useFormContext} from "react-hook-form";

const InputCreate = ({   label,
                         error,
                         children
                     }) => {
    return <Form.Group className="mb-3">
        <Form.Label>{label}</Form.Label>
        {children}
        {error && (
            <Form.Text className="text-danger">
                {error.message}
            </Form.Text>
        )}
    </Form.Group>
}

export default InputCreate;
