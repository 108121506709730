import React, {useContext, useEffect, useState} from 'react'
import {Link, useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";

import '../assets/style/sign-in.css'

import logo from '../logo-clt.png';

import {Button, Col, Form, InputGroup, Row} from 'react-bootstrap'
import Field from "../components/input/field";
import {SetAccount, SetStorage} from "../helpers/functions";
import {baseApi} from "../helpers/config";


export default function LoginPage() {
    const {
        register,
        handleSubmit,
        formState: {errors, dirtyFields},
    } = useForm({
        mode: "onBlur"
    });

    const navigate = useNavigate();
    const [pwdInputType, setPwdInputType] = useState("password");
    const [submit, setSubmit] = useState(false);

    const mySubmitHandler = (event) => {
        event.preventDefault();
    };

    const onSubmit = (form) => {

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(form)
        };


        fetch(`${baseApi()}User`, options)
            .then(r => r.json().then(data => ({status: r.status, result: data})))
            .then(data => {
                if(data.status !== 200) {
                    alert('Usuario/Contraseña incorrecta')
                    return;
                }

                SetAccount(data.result)
                navigate(`/app/`);

            })
            .catch(error => {
                alert('Usuario/Contraseña incorrecta')
                console.error(error)
            });
    }

    return <>
        <div className="form-signin w-100 m-auto">

            <div className="card mb-4 rounded-3 shadow-sm">
                <div className="card-header py-3">
                    <div style={{textAlign: 'center'}}>
                        <img src={logo} className="App-logo" alt="logo"/>
                    </div>
                </div>
                <div className="card-body">
                    <Form>


                        <Field label="Usuario" error={errors.username}>
                            <Form.Control type="text"
                                          {...register("username", {
                                              required: "Usuario es obligatorio",
                                          })}/>
                        </Field>
                        <Field label="Contraseña" error={errors.password}>
                            <InputGroup className="mb-3">
                                <Form.Control type={pwdInputType}
                                              {...register("password", {
                                                  required: "Contraseña es obligatorio",
                                              })}/>
                                <Button variant="outline-secondary"
                                        onClick={() => setPwdInputType(pwdInputType == 'password' ? 'text' : 'password')}>
                                    <i className={pwdInputType == 'password' ? "fa fa-eye" : "fa fa-eye-slash"}></i>
                                </Button>
                            </InputGroup>
                        </Field>
                        <div className="d-grid gap-2">
                            <Button
                                type="button"
                                variant="primary"
                                disabled={submit}
                                onClick={!submit ? handleSubmit(onSubmit) : null}>
                                {submit ? 'Enviando…' : 'Enviar'}
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>


    </>
}
