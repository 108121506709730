import {DocusealForm} from "@docuseal/react";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {baseApi} from "../helpers/config";


export default function SignaturePage() {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {state} = useLocation();

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const navigate = useNavigate();


    useEffect(() => {
        onSubmit({id: state.id, status: "open"},false)
    })


    const onSubmit = (form, isComplete) => {

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(form)
        };


        fetch(`${baseApi()}documents/submitters-status`, options)
            .then(r => r.json().then(data => ({status: r.status, result: data})))
            .then(data => {
                if(data.status !== 200) {
                    return;
                }
                if(isComplete) navigate(`/app/`);

            })
            .catch(error => {
                console.error(error)
            });



    }


    return <DocusealForm src={state?.url}
                         logo="https://i.postimg.cc/G28C9jVY/logosvg-1-svg.png"
                         sendCopyEmail={false}
                         withDownloadButton={false}
                         withSendCopyButton={false}
                         allowToResubmit={false}
                         preview={state.preview || false}
                         onComplete={(data) => {
                             onSubmit({id: state.id, status: data.status, complete: data}, true)
                             console.log(data);
                         }
                         }/>
}


