const ACCOUNT_KEY = "UserAccount";

export const SetAccount = (data) => {
    SetStorage(ACCOUNT_KEY, data);
};

export const GetAccount = (key = "") => {
    let result = GetStorage(ACCOUNT_KEY);

    if (!result) return null;

    if (key === "") return result;

    return result[key];
};

export const SetStorage = (key, data) => {
    window.localStorage.setItem(key, toString(data));
};

export const GetStorage = (key) => {
    return toArray(window.localStorage.getItem(key));
};

export const toArray = (input) => {
    if (!input) return null;
    try {
        return JSON.parse(input);
    } catch (e) {
        return [];
    }
};

export const toString = (input) => {
    if (!input) return null;
    return JSON.stringify(input);
};
